<template>
  <div class="p_4 max-w_50 m_auto">
    <div>
      <div class="text_center">
        <h1 class="c_acc font_6">
          Create Security Questions
        </h1>
      </div>
       
      <div class="text_center">
        <div class="text_center">
          <p><i class="far font_10 fa-lock fas c_secondary"></i></p>
          <div>
            <p> For added security, and for us to provide you better service, please select security questions and answers below.</p>
          </div>
          <p class="font_italic">Hint: Select questions and answers you will remember.</p>
        </div>
      </div>
      <form id="formSecurityQuestions" class="p_4 max-w_30 m_auto" novalidate v-on:submit.prevent="onSubmit">
        <fieldset>
          <div class="m-b_4">
            <InputSelect class="m-b_4"   :inputId="'Q1'" v-model="SecurityUser.Question1" :options="Questions1list"  :required=true :state="sq1State" @onStateChange="updateState">
              <template v-slot:default>
                Verification Question 1
              </template>
              <template v-slot:requiredAlertMessage>
                Please choose Verification Question 1.
              </template>
            </InputSelect>
            <InputText ref="TextInput" :inputId="'sq1'" :type="'text'" v-model="SecurityUser.Answer1" :required="true" :class="'br_radius'" :state="sqa1State" @onStateChange="updateState">
              <template v-slot:default>
                Answer 1
              </template>
              <template v-slot:requiredAlertMessage>
                Please submit answer before continuing.
              </template>
            </InputText>
          </div>
          <div class="m-b_4">
            <InputSelect class="m-b_4" :inputId="'Q2'" :options="Questions2list"  v-model="SecurityUser.Question2" :required=true :state="sq2State" @onStateChange="updateState">
              <template v-slot:default>
                Verification Question 2
              </template>
              <template v-slot:requiredAlertMessage>
                Please choose Verification Question 2.
              </template>
            </InputSelect>
            <InputText :inputId="'sq2'" :type="'text'" v-model="SecurityUser.Answer2" :required="true" :state="sqa2State" @onStateChange="updateState" :class="'br_radius'">
              <template v-slot:default>
                Answer 2
              </template>
              <template v-slot:requiredAlertMessage>
                Please submit answer before continuing.
              </template>
            </InputText>
          </div>
          <div class="text_center" @click="onDisabledCheck('submit')">
            <Btn :isDisabled="isDisabled">
              <div><span class="p-r_3">Submit</span></div>
              <i v-if="loading" id="btnSpinner" class="spinner fa fa-spinner fa-spin self_center"></i>
            </Btn>
          </div>
          <p class="text_center p_4 resendemail">
            <a id="notnow" class="resendemail" href="javascript:void(0);" @click="onSubmit">Not Now</a>
          </p>
        </fieldset>
      </form>
    </div>
    <div>
      <ACCMemberCare></ACCMemberCare>
    </div>
  </div>
</template>
<script>
  import InputText from '@/components/BasicForms/Input.Text.vue'
  import Btn from '@/components/subComponents/Btn.vue'
  import ACCMemberCare from "./ACCMemberCare.vue";
  import InputSelect from '@/components/BasicForms/Input.Select.vue'
  import axios from 'axios'
  export default {
    name: 'AddSecurityQuestionAnswers',
    components: {
      InputText,
      InputSelect,
      Btn,
      ACCMemberCare,
      axios
    },
    props: {
      amsid: { type: String, default: "" },
    },
    data: () => ({
      sq1State: null,
      sq2State: null,
      sqa1State: "",
      sqa2State: "",
      Questions1list: [],
      Questions2list: [],
      SecurityUser: { Question1: '', Question2: '', Answer1: '', Answer2: '',CustomerId:'' },
      loading: false,
      isskipsave :false
    }),
    computed: {
      isDisabled() {
        if (this.loading || this.sqa1State == "requiredAlert" || this.sqa2State == "requiredAlert" || this.sqa1State == "requiredAlert" || this.sqa2State == "requiredAlert") {
          return true;
        }
        if ((this.SecurityUser.Question1 == "" || this.SecurityUser.Question2 == "") || (this.SecurityUser.Question1 == null || this.SecurityUser.Question2 == null) ||(this.SecurityUser.Answer1 == "" || this.SecurityUser.Answer2 == "") || (this.SecurityUser.Answer1 == null || this.SecurityUser.Answer2 == null)) {
          return true;
        }
        else if ((this.SecurityUser.Question1.length && this.SecurityUser.Question2.length) || (this.SecurityUser.Answer1 == "" || this.SecurityUser.Answer2 == "") || (this.SecurityUser.Answer1 == null || this.SecurityUser.Answer2 == null)) {
          return (this.SecurityUser.Question1.length <= 0 && this.SecurityUser.Question2.length <= 0) && (this.SecurityUser.Answer1.length <= 0 && this.SecurityUser.Answer2.length <= 0)
        }
        //if ((this.SecurityUser.Answer1 == "" || this.SecurityUser.Answer2 == "") || (this.SecurityUser.Answer1 == null || this.SecurityUser.Answer2 == null)) {
        //  return true;
        //}
        //else if ((this.SecurityUser.Answer1.length && this.SecurityUser.Answer2.length)) {
        //  return (this.SecurityUser.Answer1.length <= 0 && this.SecurityUser.Answer2.length <= 0)
        //}
        
      },
    },
    methods: {
      onDisabledCheck(eventTargetDOM) {
        if (this.SecurityUser.Answer1 == '' || this.SecurityUser.Answer1 == null) {
          this.sqa1State = 'requiredAlert';
        } else {
          this.sqa1State = '';
        }
        if (this.SecurityUser.Answer2 == '' || this.SecurityUser.Answer2 == null) {
          this.sqa2State = 'requiredAlert';
        } else {
          this.sqa2State = '';
        }
        
          if (this.SecurityUser.Question1 == '' || this.SecurityUser.Question1 == null) { this.sq1State = 'requiredAlert'; } else { this.sq1State = ''; }
          if (this.SecurityUser.Question2 == '' || this.SecurityUser.Question2 == null) { this.sq2State = 'requiredAlert'; } else { this.sq2State = ''; }
         },
      updateState(b) {
        console.log(b.target.id);
        switch (b.target.id) {
          case 'input_sq1':
            this.sqa1State = b.state;
            break;
          case 'input_sq2':
            this.sqa2State = b.state;
            break;
          case 'input_Q1':
             this.sq1State = b.state;
            break;
          case 'input_Q2':
             this.sq2State = b.state;
            break;
          default:
            console.log('No valid state update element id.');
        }
        //if (b == 'input_Q1') {
        //  if (this.SecurityUser.Question1 == '') { this.sq1State = 'requiredAlert'; } else { this.sq1State = ''; }
        //}
        //if (b == 'input_Q2') {
        //  if (this.SecurityUser.Question2 == '') { this.sq2State = 'requiredAlert'; } else { this.sq2State = ''; }
        //}
      },
      onSubmit(b) {
        if (b.target.id == "notnow")
          this.isskipsave = true
        this.loading = true
        var postdata = {
          securityquestions: this.SecurityUser,
          bskipsave: this.isskipsave
        }
        axios
          .post('/ACCFederatedLogin/SaveQuestionAnswer' + window.location.search.toString(), postdata)
          .then((response) => {
            if (response.data) {
              if (response.data.hasOwnProperty('oAuth')) // if its oAuth then get the url and send it.
              {
                console.log('vue' + JSON.stringify(response.data))
                this.loading = false
                if (response.data.Error != '' && response.data.oAuth.toString() === 'error') {
                 // this.User.InfoMessage = response.data.Error.toString()
                  this.User.InfoCode = 1
                }
                else
                  window.location.href = response.data.URL.toString();
              }
              else { // if its POSTSP refresh the page then automatically goes to vendors page
                this.loading = false
                //this.User.MultipleAccounts = false
                //this.User.InfoCode = ''
                //this.User.InfoMessage = ''
                location.reload()
              }
            }
            else {
              this.loading = false
              location.reload()
            }
          }).catch((error) => {
         });
      },
      loadsecurityquestions(qtype) {
        var postdata = {
          type: qtype
        }
          axios
            .post('/ACCFederatedLogin/GetSecurityQuestions', postdata)
            .then((response) => {
              if (response.data) {
                if (qtype == "Q1")
                  this.Questions1list = response.data.result
                else
                  this.Questions2list = response.data.result
              }
              else {

              }
            }).catch((error) => {

            });
        
      }
    },
    created() {
    },
    beforeMount: function () {
      //this.$refs.TextInput.focus()
      this.loadsecurityquestions("Q1")
      this.loadsecurityquestions("Q2")
      this.SecurityUser.CustomerId = this.amsid
    }
  }
</script>
