<template>
  <div class="p_4 max-w_50 m_auto">
    <hr>
    <p><b>Have questions or need assistance?</b> Please contact ACC Member Care</p>
    <ul class="ul_none p-l_5">
      <li>
        <i class="fa fa-phone"></i>
        <a href="tel:+18002534636"> 1-800-253-4636</a> (Toll Free U.S. &amp; Canada)
      </li>
      <li><i class="fa fa-phone"></i> <a href="tel:2023756000">202-375-6000</a> (International)</li>
      <li><i class="fa fa-envelope"></i> <a href="https://my.acc.org/s/ContactUsMemberCare?language=en_US" target="_blank">Contact Us at Member Care</a></li>
    </ul>
  </div>
</template>

<script>
 

export default {
  name: 'ACCMemberCare'
  
}
</script>
