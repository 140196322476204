<template>
	<Question :id="id"
						:icon="icon"
						:postLabel="postLabel"
						:required="required"
						:state="thisState"
						@onClickPostLabel="$emit('onClickPostLabel')">
		<template v-slot:default>
			<slot name="default">Zip</slot>
		</template>
		<template v-slot:input>
			<input :id="'input_'+id"
						 :name="'input_'+id"
						 class="br_2 p-y_2 br_solid flex_auto p-l_4 lh_3 br_radius"
						 :type="type"
						 :value="value"
						 :required="required"
						 :placeholder="placeholder"
						 :class="inputStyles"
						 :disabled="thisState == 'disabled'"
						 @input="onInput($event)"
						 v-on:keyup.enter="$emit('keyupenter',$event.target.value)"
						 ref="input" />
		</template>
		<template v-slot:requiredAlertMessage>
			<slot name="requiredAlertMessage"></slot>
		</template>
		<template v-slot:alertMessage>
			<slot name="alertMessage"></slot>
		</template>
		<template v-slot:warningMessage>
			<slot name="warningMessage"></slot>
		</template>
		<template v-slot:successMessage>
			<slot name="successMessage"></slot>
		</template>
		<template v-slot:infoMessage>
			<slot name="infoMessage"></slot>
		</template>
		<template v-slot:accentMessage>
			<slot name="accentMessage"></slot>
		</template>
		<template v-slot:hint>
			<slot name="hint"></slot>
		</template>
	</Question>
</template>

<script>
	import Question from "./subComponent/Question";
	import baseInputFunctions from "./subComponent/baseInputFunctions.vue";
  
	export default {
		name: 'ZipInput',
		mixins: [baseInputFunctions],
		components: { Question },
		emits: ['keyupenter'],
		methods: {
      isZip(value) {
         let re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
				 if (!re.test(value)) {
            return false;
          } else {
            return true
          }
       },
			onInput: function (event) {
				if (event.target.value == "" && event.target.required) {
					this.$emit("onStateChange", { state: "requiredAlert", target: event.target })
				}
        else if ((!this.isZip(event.target.value))) {
          this.$emit("onStateChange", { state: "alert", target: event.target })
				}
				else {
					this.thisState = "";
					this.$emit("onStateChange", { state: "", target: event.target })

				}
				this.$emit('input', event.target.value)
			},
			focus() {
				this.$refs.input.focus();
			},

		}
	};
</script>

<style scoped></style>
