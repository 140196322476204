<template>
  <div class="p_4 max-w_50 m_auto">
    <div v-if="ResetPwd.StatusCode!='5'">
      <div class="text_center">
        <h1 class="c_acc font_6 text_center">Reset Your Password</h1>
        <p><i class="far font_10 fa-lock fas c_secondary"></i></p>
        <p>Enter your new password information.</p>
      </div>
      <div v-if="(ResetPwd.StatusCode=='3' || ResetPwd.StatusCode=='4')" class="alert alert-alert show" role="alert">
        <div class="relative">
          <div class="flex">
            <div class="font_10 text_center p_3 justify_center self_center">
              <i class="far fa-exclamation-triangle"></i>
            </div>
            <div v-if="ResetPwd.StatusCode=='3'" class="flex_auto p_4 p-y_2 self_center">
              <p>{{ResetPwd.StatusMessage}}</p>
            </div>
            <div v-if="ResetPwd.StatusCode=='4'" class="flex_auto p_4 p-y_2 self_center">
              <p>The link you are using to reset your password was created more than 24 hours ago and is no longer valid. To ensure your security, we request that you reset your password again <router-link to=/ForgotPassword>here</router-link>. We apologize for the inconvenience.</p>
            </div>
          </div>
        </div>
      </div>
      <form id="formResetPassword" class="p_4 max-w_50 m_auto" novalidate v-on:submit.prevent="onSubmit">
        <fieldset class="max-w_30 m_auto">
          <div class="m-b_4">
            <InputPassword :inputId="'pwd1'" v-model="ResetPwd.NewPassword" :required="true" :state="pwd1State" @input="checkPassword">
              <template v-slot:default>
                New Password
              </template>
              <template v-slot:requiredAlertMessage>
                Please enter new password.
              </template>
              <template v-slot:alertMessage>
                Password conditions not met.
              </template>
            </InputPassword>
            <!-- Conditionals -->
            <span>Password Requirements:</span>
            <div><i class="m-r_4 fa" v-bind:class="this.pwdCheck.hasCharMin ? 'fa-check-circle c_success-n1' : 'fa-ban c_alert-n1'"></i>7 characters minimum with no spaces</div>
            <div><i class="m-r_4 fa" v-bind:class="this.pwdCheck.hasNum ? 'fa-check-circle c_success-n1' : 'fa-ban c_alert-n1'"></i>Contains at least one number</div>
            <div><i class="m-r_4 fa" v-bind:class="this.pwdCheck.hasLetter ? 'fa-check-circle c_success-n1' : 'fa-ban c_alert-n1'"></i>Contains at least one letter</div>
          </div>
          <div class="m-b_4">
            <InputPassword :inputId="'pwd2'" v-model="ResetPwd.ConfirmPassword" :required="true" :state="pwd2State" @input="confirmPassword">
              <template v-slot:default>
                Confirm Password
              </template>
              <template v-slot:requiredAlertMessage>
                Please confirm your new password.
              </template>
              <template v-slot:alertMessage>
                Password does not match. Please try again.
              </template>
            </InputPassword>
          </div>
          <div class="text_center" @click="onDisabledCheck('submit')">
            <Btn :isDisabled="isDisabled">
              <span class="p-r_3">Reset Password</span>
              <i v-if="loading" id="btnSpinner" class="spinner fa fa-spinner fa-spin self_center"></i>
            </Btn>
          </div>
        </fieldset>
      </form>
    </div>
    <div v-if="ResetPwd.StatusCode=='5'">
      <div class="p_4 max-w_50 m_auto text_center">
        <h1 class="c_acc font_6 font_medium p-b_5">Reset Password</h1>
        <i class="far fa-check-circle font_10 c_success p-b_4"></i>
        <h2 class="font_6 c_success">Success!</h2>
        <p>Your password has been successfully changed. Please <router-link to="/">log in</router-link> using your new password.</p>
      </div>
    </div>
      <div>
        <ACCMemberCare></ACCMemberCare>
      </div>
    </div>
</template>
<script>
import InputPassword from '@/components/BasicForms/Input.Password.vue'
  import Btn from '@/components/subComponents/Btn.vue'
  import PasswordChecker from '@/components/subComponents/PasswordChecker.js'
  import ACCMemberCare from "./ACCMemberCare.vue";
  import axios from 'axios'
  export default {
    mixins: [PasswordChecker],
    components: { Btn, InputPassword, ACCMemberCare, axios },
    data: () => ({
      ResetPwd: {
        NewPassword: '',
        ConfirmPassword: '',
        Activation: '',
        StatusCode: '',
        StatusMessage: ''
      },
      pwd1State: "",
      pwd2State: "",
      pwdCheck: {
        hasCharMin: false,
        hasNum: false,
        hasLetter: false
      },
      loading: false
    }),
    computed: {
      isDisabled: function () {
        //NOTE: This is a heavy handed implementation. If you need to use states that should not block submission, I recommend using a string filter to determine if any alert type states exist and if so, then disable the button.
        if (this.pwd1State !== "" || this.pwd2State !== "") {
          return true;
        }
        return this.ResetPwd.NewPassword.length <= 0 || this.ResetPwd.ConfirmPassword.length <= 0;
      }
    },
    methods: {
      checkPassword: function (a) {
        if (a == "") {
          this.pwd1State = 'requiredAlert';
          return false;
        } else {
          this.pwd1State = '';
        }

        const trimmed = a.replace(/\s/g, "");
        this.ResetPwd.NewPassword = trimmed;
        this.pwdCheck.hasCharMin = this.characterMin(trimmed, 7);
        this.pwdCheck.hasNum = this.containsNumber(trimmed);
        this.pwdCheck.hasLetter = this.containsLetter(trimmed);

        if (this.pwdCheck.hasCharMin == false || this.pwdCheck.hasNum == false || this.pwdCheck.hasLetter == false) {
          this.pwd1State = 'alert';
        }
        this.confirmPassword(this.ResetPwd.ConfirmPassword);

      },
      confirmPassword: function (a) {
        if (a == "") {
          this.pwd2State = 'requiredAlert';
          return false;
        } else {
          this.pwd2State = '';
        }
        const trimmed = a.replace(/\s/g, "");
        this.ResetPwd.ConfirmPassword = trimmed;

        if (this.matchPasswords(this.ResetPwd.NewPassword, trimmed) == false) {
          this.pwd2State = "alert";
        } else {
          this.pwd2State = "";
        }
      },
      onDisabledCheck: function (e) {
        if (this.ResetPwd.NewPassword == '') { this.pwd1State = 'requiredAlert'; }
        if (this.ResetPwd.ConfirmPassword == '') { this.pwd2State = 'requiredAlert'; }
        //Probably a more graceful way to do this
        if (this.pwdCheck.hasCharMin == false || this.pwdCheck.hasNum == false || this.pwdCheck.hasLetter == false) {
          this.pwd1State = 'alert';
        }
      },
      onSubmit() {
        this.loading = true
        axios.post('/ForgotPassword/Reset', this.ResetPwd)
          .then((response) => {
            if (response.data) {
              console.log(response.data)
              this.ResetPwd = response.data
              if (response.data.StatusCode == "2")
                this.$router.push({ name: 'ForgotPassword', query: { 'activationCode': this.ResetPwd.Activation } })
              this.loading =false
            }
          }).catch((error) => {
            console.log(error)
            this.ResetPwd.StatusCode = 3
            this.ResetPwd.StatusMessage = 'An issue was encountered connecting to an ACC partner site. '
            this.loading = false
          });

      }
    },
    mounted:function() {
      console.log(this.$route.query.activationCode)
      this.ResetPwd.Activation = this.$route.query.activationCode
      console.log(this.ResetPwd.Activation)
     //if its coming from password reset email
      axios.post('/ForgotPassword/ValidateActivation', { activationCode: this.ResetPwd.Activation })
        .then((response) => {
          if (response.data) {
            console.log(response.data)
            if (response.data.StatusCode == "2")
              this.$router.push({ name: 'ForgotPassword', query: { 'activationCode': this.ResetPwd.Activation} })
            if (response.data.StatusCode == "4")
              this.ResetPwd.StatusCode = response.data.StatusCode
          }
        }).catch((error) => {
          console.log(error)
        });
    }
  }
</script>
