<template>
  <div class="p_4 max-w_50 m_auto">
      
      <div v-if="SecurityUser.StatusCode<'3'">
        <div class="text_center">
          <h1 class="c_acc font_6">
            Verify Your Identity
          </h1>
        </div>
        <div v-if="SecurityUser.StatusCode!='' && (SecurityUser.StatusCode =='1' || SecurityUser.StatusCode =='2') && SecurityUser.StatusMessage!=''" class="alert alert-alert show" role="alert">
          <div class="relative">
            <div class="flex">
              <div class="font_10 text_center p_3 justify_center self_center">
                <i class="far fa-exclamation-triangle"></i>
              </div>
              <div class="flex_auto p_4 p-y_2 self_center">
                <span v-html="SecurityUser.StatusMessage"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="text_center">
          <div class="text_center">
            <p><i class="far font_10 fa-lock fas c_secondary"></i></p>
            <div>
              <p> {{titletext}}</p>
            </div>
            <p> Answer your questions below and verify your identity.</p>
            <p class="font_italic">Hint: Answers are not case sensitive.</p>
          </div>
        </div>
        <form id="formForgotUsername" class="p_4 max-w_30 m_auto" novalidate v-on:submit.prevent="onSubmit">
          <fieldset>
            <div class="m-b_4">
              <InputText ref="TextInput" :id="'sq1'" :type="'text'" v-model="SecurityUser.Answer1" :required="true" :class="'br_radius'" :state="sq1State" @onStateChange="updateState">
                <template v-slot:default>
                  {{SecurityUser.SecurityQuestion1}}
                </template>
                <template v-slot:requiredAlertMessage>
                  Must answer question correctly. Please try again.
                </template>
              </InputText>
            </div>
            <div class="m-b_4">
              <InputText :id="'sq2'" :type="'text'" v-model="SecurityUser.Answer2" :required="true" :state="sq2State" @onStateChange="updateState" :class="'br_radius'">
                <template v-slot:default>
                  {{SecurityUser.SecurityQuestion2}}
                </template>
                <template v-slot:requiredAlertMessage>
                  Must answer question correctly. Please try again.
                </template>
              </InputText>
            </div>
            <div class="text_center" @click="onDisabledCheck('submit')">
              <Btn :isDisabled="isDisabled">
                <div><span class="p-r_3">{{buttontext}}</span></div>
                <i v-if="loading" id="btnSpinner" class="spinner fa fa-spinner fa-spin self_center"></i>
              </Btn>
            </div>
            <p class="text_center p_4 resendemail">
              <a id="btnRemember" class="resendemail" href="javascript:void(0);" @click="onSubmit">I can't remember</a>
            </p>
          </fieldset>
        </form>
      </div>
    <div>
      <ACCMemberCare></ACCMemberCare>
    </div>
  </div>
</template>
<script>
  import InputText from '@/components/BasicForms/Input.Text.vue'
  import Btn from '@/components/subComponents/Btn.vue'
  import ACCMemberCare from "./ACCMemberCare.vue";
  
  import axios from 'axios'
  export default {
    name: 'newSecurityQuestions',
    components: {
      InputText,
      Btn,
      ACCMemberCare,
      axios
    },
    props: {
      SecurityUser: { type: Object },
      loading: { type: Boolean, default: false },
      titletext: { type: String,default:'' },
      buttontext: {type:String,default:''}
    },
    data: () => ({
      sq1State: "",
      sq2State: "",
    }),
    computed: {
      isDisabled() {
        if (this.loading || this.sq1State == "requiredAlert" || this.sq2State == "requiredAlert") {
          return true;
        }
        if ((this.SecurityUser.Answer1 == null || this.SecurityUser.Answer2 == null)) {
          return true;
        }
        else if ((this.SecurityUser.Answer1.length && this.SecurityUser.Answer2.length)) {
          return (this.SecurityUser.Answer1.length <= 0 && this.SecurityUser.Answer2.length <= 0)
        }
      },
    },
    methods: {
      onDisabledCheck(eventTargetDOM) {
        if (this.SecurityUser.Answer1 == '' || this.SecurityUser.Answer1 == null) {
          this.sq1State = 'requiredAlert';
        } else {
          this.sq1State = '';
        }
        if (this.SecurityUser.Answer2 == '' || this.SecurityUser.Answer2 == null) {
          this.sq2State = 'requiredAlert';
        } else {
          this.sq2State = '';
        }
      },
      updateState(b) {
        console.log(b.target.id);
        switch (b.target.id) {
          case 'input_sq1':
            this.sq1State = b.state;
            break;
          case 'input_sq2':
            this.sq2State = b.state;
            break;
          default:
            console.log('No valid state update element id.');
        }
      },
      onSubmit(b) {
        this.$emit('onSecurityButtonClick', b);
      },
      init() {
        //if (this.SecurityUser.StatusCode =="3")
         // this.$router.push({ name: 'Notifications', params: { SecurityUser: this.SecurityUser,step :2 } })
      },
    },
    mounted: function () {
      //this.$refs.TextInput.focus()
      this.init()
     
    }
  }
</script>
