<template>
  <div class="p_4 max-w_50 m_auto">
    <div v-if="!securityquestionview">
      <input type="hidden" ref="container">
      <div class="text_center">
        <h1 class="c_acc font_6">Forgot Your Username?</h1>
      </div>
      <div v-if="User.StatusCode!='' && User.StatusCode =='1' && User.StatusMessage!=''" class="alert alert-alert show" role="alert">
        <div class="relative">
          <div class="flex">
            <div class="font_10 text_center p_3 justify_center self_center">
              <i class="far fa-exclamation-triangle"></i>
            </div>
            <div class="flex_auto p_4 p-y_2 self_center">
              <span v-html="User.StatusMessage"></span>
            </div>
          </div>
        </div>
      </div>
      <form id="formForgotUsername" class="p_4 max-w_30 m_auto" novalidate v-on:submit.prevent="onSubmit">
        <div class="text_center">
          <p><i class="far font_10 fa-lock fas c_secondary"></i></p>
          <p>Enter your email address to verify your identity and retrieve your username.</p>
        </div>
        <fieldset>
          <div class="m-b_4">
            <InputEmail ref="EmailInput" :inputId="'email'" :type="'email'" v-model="User.Email" :required="true" :placeholder="'Email'" :state="emailState" @onStateChange="updateState">
              <template v-slot:default>
                Email
              </template>
              <template v-slot:requiredAlertMessage>
                Please enter email address.
              </template>
              <template v-slot:alertMessage>
                A valid email address format is required.
              </template>
            </InputEmail>
          </div>
          <div class="text_center" @click="onDisabledCheck('all')">
            <Btn :isDisabled="isDisabled">
              <span class="p-r_3">Submit Email</span>
              <i v-if="loading" id="btnSpinner" class="spinner fa fa-spinner fa-spin self_center"></i>
            </Btn>

          </div>
        </fieldset>
      </form>
      <ACCMemberCare></ACCMemberCare>
    </div>
    <div v-if="securityquestionview">
      <div v-if="User.StatusCode =='2'">
        <SecurityQuestions :SecurityUser="User" :loading="loading" :buttontext="securityquestionbuttontext" :titletext="securityquestiontitletext" @onSecurityButtonClick="onSecurityButtonClick"></SecurityQuestions>
      </div>
      <div v-if="User.StatusCode>='3'">
        <Notifications :SecurityUser="User" :loading="loading" :step="'forgotusername'" @onResendemail="onSecurityButtonClick"></Notifications>
      </div>
    </div>
  </div>
</template>
<script>
  import InputEmail from '@/components/BasicForms/Input.Email.vue'
  import Btn from '@/components/subComponents/Btn.vue'
  import ACCMemberCare from "./ACCMemberCare.vue";
  import SecurityQuestions from '../views/newSecurityQuestions.vue'
  import Notifications from '../views/Notifications.vue'
  import axios from 'axios'
  export default {
    name: 'ForgotUserName',
    components: {
      InputEmail,
      Btn,
      ACCMemberCare,
      axios,
      SecurityQuestions,
      Notifications
    },
    data: () => ({
      loading: false,
      User: {
        UserName: '',
        Email: '',
        CannotRememberSecurityQuestion: false,
        SecurityQuestion1: '',
        Answer1: '',
        SecurityQuestion2: '',
        Answer2: '',
        Resend: false,
        StatusCode: '',
        StatusMessage: '',
        Attempts: 0
      },
      emailState: '',
      securityquestionview: false,
      securityquestiontitletext: "We need to make sure you're you before you can receive your username.",
      securityquestionbuttontext: 'Retrieve Username'
    }),
    mounted() {
      //set focus on load 
      this.$refs.EmailInput.focus();
     
    },
    methods: {
      onSecurityButtonClick: function (b) {
        if (b.target.classList.contains('resendemail'))
          this.User.CannotRememberSecurityQuestion = true
        this.onSubmit()
      },
      onDisabledCheck(eventTargetDOM) {
        if (this.User.Email == '') {
          this.emailState = 'requiredAlert';
        }
      },
      updateState(b) {
        this.emailState = b;
      },
      onSubmit() {
        if (this.User.Email.length > 0) {
          this.loading = true
          axios.post('/ForgotUsername/ForgotUsername', this.User)
            .then((response) => {
              if (response.data) {
                this.User = response.data
                if (this.User.StatusCode == "1") {
                  this.loading = false
                  this.securityquestionview = false
                  window.scrollTo(0, top);
                }
                else if (this.User.StatusCode == "2" || this.User.StatusCode == "3" || this.User.StatusCode == "4" || this.User.StatusCode == "5")
                  this.securityquestionview = true
                this.loading = false
                if (this.User.StatusCode == "2")
                  window.scrollTo(0, top);
                // this.$router.push({ name: 'SecurityQuestions', params: { User: JSON.stringify(this.User), isforgotusername :true } })
                // this.$router.push({ name: 'SecurityQuestions', query: { 'User': JSON.stringify(this.User)}})
              }
              else {
                this.loading = false
                this.securityquestionview = false
              }
            }).catch((error) => {
              this.User.StatusCode = 1
              this.User.StatusMessage = 'An issue was encountered connecting to an ACC partner site. '
              this.loading = false
              console.log('vue' + JSON.stringify(this.User))
              console.log(error)
              document.getElementById("main-content").focus()
            });
        }
      },
      brwoserback(to, from, next) {
        ////const answer = window.confirm('Changes you made may not be saved.')
        ////if (answer) {
        //// // if (this.securityquestionview) {
        ////    if (this.User.StatusCode == "3" || this.User.StatusCode == "4" || this.User.StatusCode == "5") {
        ////      alert('3')
        ////      next()
        ////    } else {
        ////      alert('2')
        ////      this.securityquestionview = false
        ////      this.User.Email = ""
        ////      this.$router.push({ name: 'ForgotUserName' })
        ////    }
        ////  //}
        ////  //else {
        ////  //  next()
        ////  //}
        ////} else {
        ////  alert('4')
        ////  next(false)
        ////}
        if (this.User.StatusCode == "3" || this.User.StatusCode == "4" || this.User.StatusCode == "5") {
          next()
        } else {
          const answer = window.confirm('Changes you made may not be saved.')
          if (answer) {
            if (this.securityquestionview) {
              this.securityquestionview = false
              this.User.Email = ""
              this.$router.push({ name: 'ForgotUserName' })
            }
            else {
              next()
            }
          }
          else {
            next(false)
          }
        }
      },
    },
    computed: {
      isDisabled() {
        if (this.loading || this.emailState == "requiredAlert" || this.emailState == "alert") {
          return true;
        }
        if (this.User.Email) {
          return this.User.Email.length <= 0;
        }
        return true;
      }
    },
      beforeRouteLeave(to, from, next) {
        this.brwoserback(to, from, next)
    },
    created() {
     // window.addEventListener('beforeunload', this.handler);
      window.addEventListener("beforeunload", (e) => {
       
       // if (this.securityquestionview) {
          if (this.User.StatusCode == "3" || this.User.StatusCode == "4" || this.User.StatusCode == "5") {
           
          } else {
          
            if (!this.success) {
             
              e.preventDefault()
              e.returnValue = ''
            }
          //  this.$router.push({ name: 'ForgotUserName' })
          }
        //} 
        return false;
      });
    },
  }
</script>
