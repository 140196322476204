<template>
  <div class="p_4 max-w_50 m_auto">
    <!--Forgotusername Messages-->
    <div v-if="step =='forgotusername'">
      <div v-if="SecurityUser.StatusCode =='3'" class="p_4 max-w_50 m_auto text_center">
        <h1 class="c_acc font_6 font_medium p-b_5">Check Your Email</h1>
        <i class="far fa-envelope font_10 p-b_4 c_info"></i>
        <p>An email has been sent with your username to the email provided. The message may take a few minutes to arrive. If you didn't receive an email from us, check your junk folder and try again.</p>
        <div class="alert alert-secondary max-w_50 m_auto m-b_5" role="alert">
          We sent your email to: <br> <span class="font_bold">{{SecurityUser.Email}}</span>
        </div>
        <p>Didn't receive an email? Resend it.</p>
        <div class="text_center resendemail" @click="onSubmit">
          <Btn class="resendemail" :isDisabled="loading">
            <span class="p-r_3">Resend Email</span>
            <i v-if="loading" id="btnSpinner1" class="spinner fa fa-spinner fa-spin self_center"></i>
          </Btn>
        </div>
        <p class="text_center p_2">
          {{SecurityUser.StatusMessage}}
        </p>
      </div>
      <div v-if="SecurityUser.StatusCode =='4'">
        <div class="p_4 max-w_50 m_auto text_center">
          <h1 class="c_acc font_6 font_medium p-b_5">Security Question Attempts Exceeded</h1>
        </div>
        <div class="alert alert-alert max-w_50 m_auto m-b_5" role="alert">
          <div class="flex">
            <div class="font_10 text_center p_3 justify_center self_center lh_0">
              <i class="far fa-exclamation-triangle"></i>
            </div>
            <div class="flex_auto p_4 p-y_2 self_center">
              <p>
                We noticed you are having trouble answering your security questions. You can retrieve your username
                by clicking the "I can't remember" instead of attempting to answer the questions on the "Verify Your Identity" form.
                If you are still having trouble, please feel free to contact our Member Care team.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="SecurityUser.StatusCode =='5'" class="p_4 max-w_50 m_auto text_center">
        <h1 class="c_acc font_6 font_medium p-b_5">Username Request</h1>
        <i class="far fa-check-circle font_10 c_success p-b_4"></i>
        <h2 class="font_6 c_success">Success!</h2>
        <p>Your username was retrieved successfully.</p>
        <div class="alert alert-success">
          <div class="flex-row">
            <div class="col-md-12">
              <div class="m-l_05">
                <p>Your username is <strong>{{SecurityUser.UserName}}</strong></p>
              </div>
            </div>
          </div>
        </div>
        <p>Please login <router-link to="/">here</router-link></p>
        <p>A confirmation email will be sent shortly.</p>
      </div>
    </div>
    <!--Forgotpassword Messages-->
    <div v-if="step =='forgotpassword'">
      <div v-if="SecurityUser.StatusCode =='3'" class="p_4 max-w_50 m_auto text_center">
        <h1 class="c_acc font_6 font_medium p-b_5">Check Your Email</h1>
        <i class="far fa-envelope font_10 p;-b_4 c_info"></i>
        <p>An email has been sent with a link to reset your password to the email provided. The message may take a few minutes to arrive. If you didn't receive an email from us, check your junk folder and try again.</p>
        <p>Note: For security purposes, this link is only valid for the next 24 hours.</p>
        <div class="alert alert-secondary max-w_50 m_auto m-b_5" role="alert">
          We sent your email to: <br> <span class="font_bold">{{SecurityUser.Email}}</span>
        </div>
        <p>Didn't receive an email? Resend it.</p>
        <div class="text_center resendemail" @click="onSubmit">
          <Btn class="resendemail" :isDisabled="loading">
            <span class="p-r_3">Resend Email</span>
            <i v-if="loading" id="btnSpinner1" class="spinner fa fa-spinner fa-spin self_center"></i>
          </Btn>
        </div>
        <p class="text_center p_2">
          {{SecurityUser.StatusMessage}}
        </p>
      </div>
      <div v-if="SecurityUser.StatusCode =='4'">
        <div class="p_4 max-w_50 m_auto text_center">
          <h1 class="c_acc font_6 font_medium p-b_5">Security Question Attempts Exceeded</h1>
        </div>
        <div class="alert alert-alert max-w_50 m_auto m-b_5" role="alert">
          <div class="flex">
            <div class="font_10 text_center p_3 justify_center self_center lh_0">
              <i class="far fa-exclamation-triangle"></i>
            </div>
            <div class="flex_auto p_4 p-y_2 self_center">
              <p>
                We noticed you are having trouble answering your security questions.
                You can reset your password by clicking "I can't remember" instead of attempting to answer the questions on the "Verify Your Identity" form.
                If you are still having trouble, please feel free to contact our Member Care Team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
        <ACCMemberCare></ACCMemberCare>
      </div>
    </div>
</template>
<script>
  import ACCMemberCare from "./ACCMemberCare.vue";
  import Btn from '@/components/subComponents/Btn.vue'
  export default {
    name: 'Notifications',
    components: {
      ACCMemberCare,
      Btn
    },
    props: {
      SecurityUser: { type: Object },
      step: { type: String, default: '' },
      loading: { type: Boolean, default: false }
    },
    methods: {
      onSubmit(b) {
        this.SecurityUser.Resend = true
        this.$emit('onResendemail', b);
      }
    }
  }
</script>
