
<!--<template>
  <div class="p_4 max-w_50 m_auto">
    <div v-if="!securityquestionview">
      <div v-if="User.InfoCode>='3'">
        <h1 class="c_acc font_6 text_center">Your Account Has Been Temporarily Locked</h1>
        <div class="alert alert-alert show" role="alert">
          <div class="relative">
            <div class="flex">
              <div class="font_10 text_center p_3 justify_center self_center">
                <i class="far fa-exclamation-triangle"></i>
              </div>
              <div class="flex_auto p_4 p-y_2 self_center">
                <span v-html="User.InfoMessage"></span>
              </div>
            </div>
          </div>
        </div>
        <ACCMemberCare></ACCMemberCare>
      </div>
      <div v-if="User.InfoCode<'3'">
        <h1 class="c_acc font_6 text_center">Log In To Your Account</h1>
        <div v-if="User.InfoCode!='' && User.MultipleAccounts" class="alert alert-alert show" role="alert">
          <div class="relative">
            <div class="flex">
              <div class="font_10 text_center p_3 justify_center self_center">
                <i class="far fa-exclamation-triangle"></i>
              </div>
              <div class="flex_auto p_4 p-y_2 self_center">
                <h4 class="alert-heading">Duplicate Accounts</h4>
                <hr>
                <span>
                  That email address is associated with multiple ACC accounts. Please call the ACC Member Care to consolidate your acc.org accounts:

                  1-800-253-4636 (Toll Free US & Canada)
                  202-375-6000 (Outside US & Canada)
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="User.InfoCode!=''  && !User.MultipleAccounts" class="alert alert-alert show" role="alert">
          <div class="relative">
            <div class="flex">
              <div class="font_10 text_center p_3 justify_center self_center">
                <i class="far fa-exclamation-triangle"></i>
              </div>
              <div class="flex_auto p_4 p-y_2 self_center">
                <span v-html="User.InfoMessage"></span>
              </div>
            </div>
          </div>
        </div>
        <form id="formLogin" class="p_4 max-w_50 m_auto">
          <fieldset class="max-w_30 m_auto">
            <div class="m-b_3">
              <InputText :id="'username'" :type="'text'" v-model.trim="User.UserName" :required="true" :class="'br_radius'" ref="TextInput"
                         :state="usernameState" :placeholder="'Username'" @input="onDisabledCheck('username')" @keyupenter="LoginValidate">
                <template v-slot:default :class="font_bold">
                  Email or Username
                </template>
                <template v-slot:requiredAlertMessage>
                  Please enter Email or Username
                </template>
              </InputText>
            </div>
            <div class="m-b_3">-->
                <!--   <router-link to="/ForgotUserName" tabindex="-1">Forgot Username?</router-link>-->
                <!--<a v-bind:href="$AccUrl + '/ForgotUsername'">Forgot Username?</a>
            </div>
            <div class="m-b_3">
              <InputPassword  :inputId="'password'" v-model.trim="User.Password" :type="'password'" :required="true" :placeholder="'Password'"
                             :state="passwordState" @input="onDisabledCheck('password')" @change="onDisabledCheck('password')" @keyupenter="LoginValidate" showHideToggle>
                <template v-slot:default class="font_bold">
                  Password
                </template>
                <template v-slot:requiredAlertMessage>
                  Please enter Password
                </template>
                <template v-slot:hint>
                  At least 7 characters with at least 1 number and 1 letter
                </template>
              </InputPassword>
            </div>
            <div class="m-b_4">
                <a v-bind:href="$AccUrl +'/ForgotPassword'">Forgot Password?</a>-->
                <!--<router-link to="/ForgotPassword">Forgot Password?</router-link>-->
            <!--</div>
    <div class="m-b_4">-->
      <!--<input type="checkbox" id="RememberMe" value="RememberMe" v-model="user.rememberme">
      <label for="RememberMe"> Remember Password</label>-->
                <!--<CheckboxInput v-model="User.RememberMeObj"></CheckboxInput>
            </div>
          </fieldset>
          <div class="text_center" @click="onDisabledCheck('all')">
            <Btn type="button" id="loginsubmit" :isDisabled="isDisabled" @onClick="LoginValidate">
              <span class="p-r_3">Log In</span>
              <i v-if="loading" id="btnSpinner" class="spinner fa fa-spinner fa-spin"></i>
            </Btn>
          </div>
          <p class="text_center p_4">
            <router-link to="/PreRegistration">Create Free Account</router-link>
          </p>
         
        </form>
      </div>
    </div>
    <div v-if="securityquestionview">
      <AddSecurityQuestionAnswers :amsid="customerId"></AddSecurityQuestionAnswers>
    </div>
    <div>
      <agreement-modal v-if="isShowModal" :amsid="customerId" @onClose="toggleModal" @onSave="SaveAgreement" />
    </div>

  </div>
</template>-->
<script src="https://unpkg.com/vue-recaptcha@latest/dist/vue-recaptcha.js"></script>
<script>
  import InputText from '@/components/BasicForms/Input.Text.vue'
  import InputPassword from "@/components/BasicForms/Input.Password.vue"
  import Btn from "@/components/subComponents/Btn.vue";
  import CheckboxInput from "@/components/BasicForms/Input.Checkbox.vue";
  import ACCMemberCare from "./ACCMemberCare.vue";
  import AddSecurityQuestionAnswers from '../views/AddSecurityQuestionAnswers.vue'
  import AgreementModal from '../views/UserAgreementModal.vue'
  import axios from 'axios' 
  export default {
    name: 'Login',
    components: {
      InputText,
      InputPassword,
      Btn,
      CheckboxInput,
      ACCMemberCare,
      axios,
      AddSecurityQuestionAnswers,
      AgreementModal 
    },
    data: () => ({
      HomeUrl: '',
      User: {
        UserName: '',
        Password: '',
        RememberMeObj: { label: 'Keep me logged in on this computer', selected: true },
        MultipleAccounts: false,
        InfoCode: '',
        InfoMessage: '',
        HasUserAgreement: false
      },
      loading: false,
      passwordState: '',
      usernameState: '',
      securityquestionview: false,
      customerId: '',
      isShowModal: false,
    }),
    methods: {
      onEvent() {
        // when you need a reCAPTCHA challenge
        this.$refs.recaptcha.execute();
      },
      onVerify: function (response) {
        console.log('Verify: ' + response)
      },
      init: function () {
         this.state = ''
        this.HomeUrl = document.getElementById("main-content").getAttribute("data-url-name")
      },
      toggleModal() {
        this.isShowModal = !this.isShowModal
        this.loading = this.isShowModal == true ? true : false
      },
      onDisabledCheck(eventTargetDOM) {
        if (eventTargetDOM == 'all' || eventTargetDOM == 'username') {
          if (this.User.UserName == '') { this.usernameState = 'requiredAlert'; } else { this.usernameState = ''; }
        }
        if (eventTargetDOM == 'all' || eventTargetDOM == 'password') {
          if (this.User.Password == '') { this.passwordState = 'requiredAlert'; } else { this.passwordState = ''; }
        }

      },
      LoginValidate: function () {
        if (this.User.UserName.length > 0 && this.User.Password.length > 0) {
          console.log('/ACCLogin' + window.location.search.toString())
          console.log(this.User)
          this.loading = true
          axios
            .post('/ACCFederatedLogin/ACCLogin' + window.location.search.toString(), this.User)
            .then((response) => {
              if (response.data) {
                if (response.data.hasOwnProperty('UserName')) {
                  this.User = response.data
                  this.loading = false
                  console.log('vue' + JSON.stringify(this.User))
                }
                else if (response.data.hasOwnProperty('hasuseragreement')) {
                  this.customerId = response.data.customerId
                  this.securityquestionview = false
                  this.toggleModal()
                }
                else if (response.data.hasOwnProperty('hassecurityquestionanswer')) {
                  this.securityquestionview = true
                  this.customerId = response.data.customerId
                }
                else if (response.data.hasOwnProperty('oAuth')) // if its oAuth then get the url and send it.
                {
                  console.log('vue' + JSON.stringify(response.data))
                  this.loading = false
                  if (response.data.Error != '' && response.data.oAuth.toString() === 'error') {
                    this.User.InfoMessage = response.data.Error.toString()
                    this.User.InfoCode = 1
                  }
                  else
                    window.location.href = response.data.URL.toString();
                }
                else { // if its POSTSP refresh the page then automatically goes to vendors page
                  this.loading = false
                  this.User.MultipleAccounts = false
                  this.User.InfoCode = ''
                  this.User.InfoMessage = ''
                  location.reload()
                }
              }
              else {
                this.loading = false
                location.reload()
              }
            }).catch((error) => {
              this.User.InfoCode = 1
              this.User.InfoMessage = 'An issue was encountered connecting to an ACC partner site. '
              this.loading = false
              console.log('vue' + JSON.stringify(this.User))
              console.log(error)
            });
        }
      },
      SaveAgreement: function (data) {
        this.toggleModal()
        var postData = { customerid: data }
        axios
          .post('/ACCFederatedLogin/SaveAgreement', postData)
          .then((response) => {
            if (response.data.success) {
              this.LoginValidate()

            }

          }).catch((error) => {
            this.User.InfoCode = 1
            this.User.InfoMessage = 'An issue was encountered connecting to an ACC partner site. '
            this.loading = false
            console.log('vue' + JSON.stringify(this.User))
            console.log(error)
          });
      }
    },

    computed:
    {
      isDisabled() {
        return this.User.UserName.length > 0 && this.User.Password.length > 0 ? false : true
      }

    },
    beforeMount: function () { 
      window.location.href = document.getElementById("main-content").getAttribute("data-saleforcelogin") + window.location.search.toString()
      this.init()
    },
    mounted: function () {
       this.$refs.TextInput.focus()
    }
  }
</script>
